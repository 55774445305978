exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-forms-js": () => import("./../../../src/pages/forms.js" /* webpackChunkName: "component---src-pages-forms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-js": () => import("./../../../src/pages/information.js" /* webpackChunkName: "component---src-pages-information-js" */),
  "component---src-pages-newsletters-js": () => import("./../../../src/pages/newsletters.js" /* webpackChunkName: "component---src-pages-newsletters-js" */),
  "component---src-pages-our-community-js": () => import("./../../../src/pages/our-community.js" /* webpackChunkName: "component---src-pages-our-community-js" */),
  "component---src-pages-photo-gallery-js": () => import("./../../../src/pages/photo-gallery.js" /* webpackChunkName: "component---src-pages-photo-gallery-js" */),
  "component---src-pages-principals-message-js": () => import("./../../../src/pages/principals-message.js" /* webpackChunkName: "component---src-pages-principals-message-js" */),
  "component---src-pages-teaching-learning-js": () => import("./../../../src/pages/teaching-learning.js" /* webpackChunkName: "component---src-pages-teaching-learning-js" */),
  "component---src-templates-photo-gallery-page-jsx": () => import("./../../../src/templates/photo-gallery-page.jsx" /* webpackChunkName: "component---src-templates-photo-gallery-page-jsx" */)
}

